<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" 
        id="side-menu" 
        #sideMenu
    >
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" 
            *ngIf="item.isTitle"
            [hidden]="!item.isVisible"
        >
          {{ item.label | translate }}
        </li>
        <li *ngIf="!item.isTitle && !item.isLayout"
            [hidden]="!item.isVisible"
        >
          <a *ngIf="hasItems(item)" 
             href="javascript:void(0);"
             class="is-parent pointer"
             [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
          >
            <i class="{{item.icon}}" 
               *ngIf="item.icon"
               style="font-size: 15.5px;"
            ></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" 
                  *ngIf="item.badge"
            >
              {{item.badge.text | translate}}
            </span>
          </a>

          <a [routerLink]="item.link" 
             *ngIf="!hasItems(item)" 
             class="side-nav-link-ref pointer" 
             routerLinkActive="active"
             [routerLinkActiveOptions]="{ exact: true }"
          >
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span>{{ item.label | translate }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" 
                  *ngIf="item.badge"
            >
              {{item.badge.text | translate}}
            </span>
          </a>

          <ul *ngIf="hasItems(item)" 
              class="sub-menu" 
              aria-expanded="false"
          >
            <li *ngFor="let subitem of item.subItems">
              <span [hidden]="!subitem.isVisible">
                <a [routerLink]="subitem.isLink ? subitem.link : null" 
                   *ngIf="!hasItems(subitem)" 
                   (click)="handleActionClicked(subitem.actionName)"
                   class="side-nav-link-ref pointer"
                   [attr.data-parent]="subitem.parentId" 
                   routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }"
                >
                  <i class="{{subitem.icon}}" 
                     style="font-size: 15.5px;"
                     *ngIf="subitem.icon"
                  >
                  </i>
                  {{ subitem.label | translate}}
                </a>
                <a *ngIf="hasItems(subitem)" 
                   class="side-nav-link-a-ref has-arrow" 
                   href="javascript:void(0);"
                   [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label | translate}}
                </a>
                <ul *ngIf="hasItems(subitem)" 
                    class="sub-menu mm-collapse" 
                    aria-expanded="false"
                >
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a [attr.data-parent]="subSubitem.parentId" 
                       [routerLink]="subSubitem.link" 
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{ exact: true }"
                       class="side-nav-link-ref pointer"
                    >
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->