import { MenuItem } from './menu.model';
import { UserSecurityLevelEnum, UserGroupEnum } from 'degarmo-lib';

const { Administrator, Manager, Executive } = UserSecurityLevelEnum;
const { Employee, Proctor } = UserGroupEnum;

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx bx-home-circle',
    subItems: [
      {
        id: 101,
        label: 'MENUITEMS.DASHBOARDS.LIST.PARTICIPANTS',
        link: '/dashboards/participants',
        isLink: true,
        parentId: 1,
        isVisible: true,
        allowedUserGroups: [Employee, Proctor],
        allowedSecurityLevelIDs: [Manager, Executive, 6, Administrator],
        authenticate: true,
      },
      {
        id: 102,
        label: 'MENUITEMS.DASHBOARDS.LIST.PACKAGES',
        link: '/dashboards/packages',
        isLink: true,
        parentId: 1,
        isVisible: true,
        allowedUserGroups: [Proctor],
        allowedSecurityLevelIDs: [Executive, Administrator],
        authenticate: true,
      },
      {
        id: 103,
        label: 'MENUITEMS.DASHBOARDS.LIST.INTERVIEWS',
        link: '/dashboards/interviews',
        isLink: true,
        parentId: 1,
        isVisible: true,
        allowedUserGroups: [Proctor],
        authenticate: true,
      },
    ],
  },
  {
    id: 2,
    label: 'MENUITEMS.TALENT.TEXT',
    icon: 'bx dripicons-user-group',
    subItems: [
      {
        id: 201,
        label: 'MENUITEMS.TALENT.LIST.SEARCH',
        link: '/talent/search',
        isLink: true,
        isVisible: true,
        authenticate: true,
        allowedUserGroups: [Proctor],
      },
      {
        id: 202,
        label: 'MENUITEMS.TALENT.LIST.ADD',
        link: '/talent/add',
        isLink: true,
        isVisible: true,
        authenticate: true,
        allowedUserGroups: [Proctor],
      },
    ],
  },
  {
    id: 3,
    label: 'MENUITEMS.ANALYTICS.TEXT',
    icon: 'bx dripicons-graph-pie',
    subItems: [
      {
        id: 302,
        label: 'MENUITEMS.ANALYTICS.LIST.VISUAL',
        link: '/analytics/visual',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Proctor],
        allowedSecurityLevelIDs: [Executive, Administrator],
        authenticate: true,
      },
      {
        id: 301,
        label: 'MENUITEMS.ANALYTICS.LIST.STANDARD',
        link: '/analytics/standard',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Proctor],
        allowedSecurityLevelIDs: [Executive, Administrator],
        authenticate: true,
      },
      {
        id: 303,
        label: 'MENUITEMS.ANALYTICS.LIST.SURVEY_DATA',
        isVisible: true,
        link: '/analytics/survey-data',
        isLink: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
    ],
  },
  {
    id: 4,
    label: 'MENUITEMS.INTERVIEWS.TEXT',
    icon: 'fas fa-person-booth',
    subItems: [
      {
        id: 401,
        label: 'MENUITEMS.INTERVIEWS.LIST.LIBRARY',
        link: '/interviews',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee, Proctor],
        allowedSecurityLevelIDs: [Manager, Executive, 6, Administrator],
        authenticate: true,
      },
      {
        id: 402,
        label: 'MENUITEMS.INTERVIEWS.LIST.BUILDER',
        link: '/interviews/builder',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee, Proctor],
        allowedSecurityLevelIDs: [Executive, Administrator],
        authenticate: true,
      },
      {
        id: 403,
        label: 'MENUITEMS.INTERVIEWS.LIST.CONTENTS',
        link: '/interviews/contents',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee, Proctor],
        allowedSecurityLevelIDs: [Manager, Executive, 6, Administrator],
        authenticate: true,
      },
    ],
  },
  {
    id: 5,
    label: 'MENUITEMS.EDITORS.TEXT',
    icon: 'bx bxs-edit',
    subItems: [
      {
        id: 506,
        label: 'MENUITEMS.EDITORS.LIST.ASSESSMENTS',
        link: '/editors/assessments',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 501,
        label: 'MENUITEMS.EDITORS.LIST.FORMS',
        link: '/editors/forms',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 502,
        label: 'MENUITEMS.EDITORS.LIST.COMMUNICATION',
        link: '/editors/communication-templates',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 503,
        label: 'MENUITEMS.EDITORS.LIST.PACKAGES',
        link: '/editors/packages',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 504,
        label: 'MENUITEMS.EDITORS.LIST.INSTRUCTIONS',
        link: '/editors/instructions',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 507,
        label: 'MENUITEMS.EDITORS.LIST.STATEMENT_TRANSLATIONS',
        link: '/editors/statement-translations',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
    ],
  },
  {
    id: 6,
    label: 'MENUITEMS.TOOLS.TEXT',
    icon: 'fas fa-tools',
    subItems: [
      {
        id: 601,
        label: 'MENUITEMS.TOOLS.LIST.TOKEN_GENERATOR',
        link: '/tools/token-generator',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
    ],
  },
  {
    id: 7,
    label: 'MENUITEMS.ADMIN.TEXT',
    icon: 'fas fa-user-cog',
    subItems: [
      {
        id: 701,
        label: 'MENUITEMS.ADMIN.LIST.COMPANIES',
        link: '/admin/companies',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 707,
        label: 'MENUITEMS.ADMIN.LIST.CUSTOM_RULES',
        link: '/admin/custom-rules',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 708,
        label: 'MENUITEMS.ADMIN.LIST.BILLING_REPORTS',
        link: '/admin/billing-reports',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 709,
        label: 'MENUITEMS.ADMIN.LIST.BUSINESS_REVIEW',
        link: '/admin/business-review',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 702,
        label: 'MENUITEMS.ADMIN.LIST.USERS',
        link: '/admin/users',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee, Proctor],
        allowedSecurityLevelIDs: [Administrator],
        authenticate: true,
      },
      {
        id: 707,
        label: 'MENUITEMS.ADMIN.LIST.CANDIDATES',
        link: '/admin/candidates',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 706,
        label: 'MENUITEMS.ADMIN.LIST.CREATE_EMPLOYEE',
        link: '/admin/employees/create',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 705,
        label: 'MENUITEMS.ADMIN.LIST.NOTIFICATIONS',
        link: '/admin/notifications',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 703,
        label: 'MENUITEMS.ADMIN.LIST.TAGS',
        link: '/admin/tags',
        isLink: true,
        isVisible: true,
        allowedUserGroups: [Employee],
        authenticate: true,
      },
      {
        id: 710,
        label: 'MENUITEMS.ADMIN.LIST.CREDIT_STATUS',
        link: '/admin/credit-status',
        isLink: true,
        isVisible: true,
        authenticate: true,
        allowedUserGroups: [Proctor],
        allowedSecurityLevelIDs: [Administrator],
      },
    ],
  },
  {
    id: 8,
    label: 'MENUITEMS.SUPPORT.TEXT',
    icon: 'mdi mdi-briefcase-variant-outline',
    subItems: [
      {
        id: 802,
        label: 'MENUITEMS.SUPPORT.LIST.CONTACT_US',
        link: '/support/contact-us',
        isLink: true,
        isVisible: true,
        authenticate: true,
        allowedUserGroups: [Proctor],
      },
      {
        id: 804,
        label: 'MENUITEMS.SUPPORT.LIST.NOTIFICATIONS',
        link: '/support/notifications',
        isLink: true,
        isVisible: true,
        authenticate: true,
      },
      // {
      //     id: 805,
      //     label: 'MENUITEMS.SUPPORT.LIST.RELEASE_NOTES',
      //     link: '/support/release-notes',
      //     isLink: true,
      //     isVisible: true,
      //     allowedUserGroups: [Employee, Proctor],
      //     authenticate: true
      // },
      {
        id: 806,
        label: 'MENUITEMS.SUPPORT.LIST.HELP_TICKET',
        isVisible: true,
        link: '/help-ticket',
        isLink: false,
        actionName: 'helpTicket',
        allowedUserGroups: [Proctor],
        authenticate: true,
      },
    ],
  },
];
