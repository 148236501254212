import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService, AlertsService } from 'degarmo-lib';
import { NotificationService, UrlService, SortService, UserAccountService, ProspectService, NotificationDisplay, CandidateSearchModel, HelpTicketService } from 'degarmo-lib';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Observable, OperatorFunction, map } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    standalone: false
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  unreadNotificationCount: number = 0;
  unreadNotifications: NotificationDisplay[] = [];
  candidateSearchText: any;
  candidates: any[] = [];

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  constructor(@Inject(DOCUMENT) private document: any,
              private router: Router,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService,
              public accountInfoService: AccountInfoService,
              private userAccountService: UserAccountService,
              private notificationService: NotificationService,
              private urlService: UrlService,
              private sortService: SortService,
              private prospectService: ProspectService,
              private helpTicketService: HelpTicketService,
              private alertsService: AlertsService
  ) {
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;
  loginChangeSubscription: any;
  addCandidateSubscription: any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @ViewChild('notificationDropdown', { static: true }) notificationDropdown: NgbDropdown;

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);

    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.urlService.previousUrl$
      .subscribe({
        next: url => {
          if (this.accountInfoService.getUserLoginInfo()) {
            this.notificationService.getNotifications(false)
              .subscribe({
                next: notifications => {
                  const unreadNotifications: NotificationDisplay[] = [];

                  notifications.forEach((notification: NotificationDisplay) => {
                    if (!notification.isRead)
                      unreadNotifications.push(notification);
                  });

                  this.sortService.quickSort(unreadNotifications, 'timePosted', true);
                  this.unreadNotifications = unreadNotifications;
                }
              });
          }
        }
      });

    this.notificationService.unreadNotificationsCount$
      .subscribe({
        next: unreadNotificationCount => {
          this.unreadNotificationCount = unreadNotificationCount;
        }
      });

    this.loginChangeSubscription = this.userAccountService.subscribeToLoginChanged((loggedIn: boolean) => {
      this.updateCandidateList(loggedIn);
    });

    this.addCandidateSubscription = this.prospectService.subscribeToCandidatesAdded((candidateAdded: boolean) => {
      this.updateCandidateList(candidateAdded);
    });
  }

  ngOnDestroy(): void {
    this.loginChangeSubscription?.unsubscribe();
    this.addCandidateSubscription?.unsubscribe();
  }

  updateCandidateList = (update: boolean) => {
      const userLoginInfo = this.accountInfoService.getUserLoginInfo();
      if (update && userLoginInfo && userLoginInfo.isProctor) {
        this.prospectService.getProspectList()
        .subscribe({
          next: (res: CandidateSearchModel[]) => {
            this.candidates = res;
          }
        });
      } else {
        this.candidates = [];
      }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.userAccountService.logOutUser();
  }

  /**
   * Stop impersonation
   * */
  stopImpersonation() {
    this.userAccountService.stopImpersonating();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboards/participants']);
    this.alertsService.success('Impersonation Stopped');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Opens help ticket modal
   */
  createHelpTicket = () => {
    this.helpTicketService.openHelpTicketModal();
  }

  getFullScreenIconTooltipLabel() {
    return this.document.body.classList.contains('fullscreen-enable') ? 'Exit Full Screen' : 'Full Screen';
  }

  closeNotificationDropdown() {
    this.notificationDropdown.close();
  }

  searchCandidates: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((text: string) => !text ? []
      : this.candidates.filter((candidate: CandidateSearchModel) => {
        return candidate.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
               candidate.email.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
               candidate.applicantID.toLowerCase().indexOf(text.toLowerCase()) > -1;
      }).slice(0, 10))
  )

  formatter = ((x: CandidateSearchModel) => x.name);

  handleCandidateClicked = (candidate: any) => {
    candidate.preventDefault();

    if (candidate.item && candidate.item.applicantID) {
      this.candidateSearchText = null;
      this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
      this.prospectService.goToParticipantRecord(candidate.item, 0);
    }
  }
}
