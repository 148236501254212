import { Component, Input, OnInit } from '@angular/core';
import packageJson from '../../../../../package.json';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  @Input() footerClassName: string = 'footer';

  // set the currenr year
  year: number = new Date().getFullYear();
  version: string = packageJson.version;

  constructor() { }

  ngOnInit() {
  }

}
